import React from 'react';

import { SimpleGrid, Flex, Text, Center, Box } from '@chakra-ui/react';
import {
  maxWidths,
  Loader,
  Button,
  EColor,
} from '@fanatics-live/common-components';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import GridItem from '~/features/research/components/SearchResults/GridItem';
import SalesHistory from '~/features/research/types/SalesHistory';
import { useGTMEvents } from '~/hooks/useGTMEvents';
import { FeatureFlags } from '~/utils/featureFlags';

function SearchResults({
  data,
  getNextPage,
  hasMorePages,
  loggedIn,
  isPending,
}) {
  const { pushGTMEvent } = useGTMEvents();

  const { treatments } = useSplitTreatments({
    names: [FeatureFlags.WEB_ENABLE_SH_LINK_TO_CW_PDP],
  });
  const enableSalesHistoryLinkToCollectWebPDP =
    treatments[FeatureFlags.WEB_ENABLE_SH_LINK_TO_CW_PDP]?.treatment === 'on';

  const onSeeMoreButtonClick = () => {
    pushGTMEvent('salesHistorySeeMoreCta');

    getNextPage();
  };
  return (
    <Flex direction="column" maxWidth={maxWidths} px="15px" width="100%">
      {(!data || data?.length === 0) && !isPending ? (
        <Center height="250px">
          <Text color="#999" fontWeight={600} pt="10px">
            No Results found
          </Text>
        </Center>
      ) : (
        <Box>
          <SimpleGrid gap={6} minChildWidth={{ base: '280px', md: '280px' }}>
            {(data || []).map((d) => (
              <GridItem
                key={d.id}
                {...d}
                enableSalesHistoryLinkToCollectWebPDP={
                  enableSalesHistoryLinkToCollectWebPDP
                }
                loggedIn={loggedIn}
              />
            ))}
          </SimpleGrid>
          {isPending && !!data?.length && (
            <Box position="relative">
              <Loader left="calc(50% - 36px)" p="20px" position="absolute" />
            </Box>
          )}

          {hasMorePages && !!data?.length && (
            <Flex justify="center">
              <Button
                inverted
                _hover={{
                  bg: EColor.BrandDark,
                  borderColor: EColor.BrandDark,
                  color: EColor.White,
                  transform: 'scale(1.1)',
                }}
                borderWidth="2px"
                fontSize="16px"
                isDisabled={isPending}
                mt="70px"
                size="sm"
                width="fit-content"
                onClick={onSeeMoreButtonClick}
              >
                see more
              </Button>
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
}

SearchResults.propTypes = {
  data: PropTypes.arrayOf(SalesHistory),
  name: PropTypes.string,
  getNextPage: PropTypes.func,
  loggedIn: PropTypes.bool,
  hasMorePages: PropTypes.bool,
  isPending: PropTypes.bool,
};

export default React.memo(SearchResults);
