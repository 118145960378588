import React, { useMemo } from 'react';

import { CacheProvider } from '@emotion/react';

import 'focus-visible/dist/focus-visible';
import createCache from '@emotion/cache';
import {
  ApiClient,
  ApiProvider,
  Auth,
  AuthProvider,
  ModalProvider,
  useFetchProfile,
} from '@fanatics-live/common-components';
import { After } from '@jaredpalmer/after';
import { SplitFactoryProvider } from '@splitsoftware/splitio-react';
import PropTypes from 'prop-types';
import { SWRConfig } from 'swr';
import { DatadogProvider } from '~/components/DatadogProvider';

import Theme from './Theme';
import { MainProvider } from '../contexts/MainContext';
import routes from '../features/nav/routes';
import MainApp from '../ssr/App';
import { getEnv } from '../utils/env';
import apiFetcher from '../utils/swr';
import '../utils/wdyr';
import '../ssr/client.css';

const { AUTH_URL, MOBILE_URL, REACT_APP_API_URL, SPLIT_CLIENT_KEY } = getEnv();

const key = 'custom';
const cache = createCache({ key });
const auth = new Auth(AUTH_URL, MOBILE_URL, 'v2');
export const apiClient = new ApiClient(auth, REACT_APP_API_URL);

export function AppProvider({ data }) {
  const { authState, isInitializing } = useFetchProfile(auth, apiClient);
  const splitKey = authState.profile?.userUuid || 'anonymous';

  const splitConfig = useMemo(() => {
    return {
      core: {
        authorizationKey: SPLIT_CLIENT_KEY,
        key: splitKey,
      },
    };
  }, [splitKey]);

  return (
    <CacheProvider value={cache}>
      <SplitFactoryProvider config={splitConfig}>
        <DatadogProvider>
          <ApiProvider value={apiClient}>
            <AuthProvider value={authState}>
              <MainProvider
                value={{
                  isInitializing,
                }}
              >
                <Theme>
                  <ModalProvider
                    // TODO: hardcoded for now, use real FF after migrating to split.io
                    addPaymentMethodLoginFlow={false}
                    checkoutApiUrl={getEnv('CHECKOUT_API_URL')}
                    fanIdBaseUrl={getEnv('FAN_ID_BASE_URL')}
                    fanIdClientId={getEnv('FAN_ID_CLIENT_ID')}
                    fanIdPreview={getEnv('FAN_ID_PREVIEW')}
                    fanaticsCollectBaseUrl={getEnv('MONOLITH_URL')}
                    graphqlApiUrl={getEnv('GRAPHQL_API_URL')}
                    membersApiUrl={getEnv('MEMBERS_API_URL')}
                    stripeMarketplaceKey={getEnv('MARKETPLACE_PUBLISHABLE_KEY')}
                  >
                    <SWRConfig
                      value={{
                        refreshInterval: 0,
                        revalidateOnFocus: false,
                        revalidateOnReconnect: false,
                        fetcher: (...args) => apiFetcher(apiClient, ...args),
                      }}
                    >
                      <MainApp auth={auth} isInitializing={isInitializing}>
                        <After
                          apiClient={apiClient}
                          auth={auth}
                          data={data}
                          routes={routes}
                        />
                      </MainApp>
                    </SWRConfig>
                  </ModalProvider>
                </Theme>
              </MainProvider>
            </AuthProvider>
          </ApiProvider>
        </DatadogProvider>
      </SplitFactoryProvider>
    </CacheProvider>
  );
}

AppProvider.propTypes = {
  data: PropTypes.object,
};
