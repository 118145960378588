import React from 'react';

import { Flex, Text } from '@chakra-ui/react';
import { ETextVariant, EColor } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { formatToMoney } from '~/utils/utils';

function ItemPrice({
  purchasePrice,
  bpProps = {},
  priceProps = {},
  flexProps = {},
  auctionType,
}) {
  return (
    <Flex alignItems="end" gap="2px" {...flexProps}>
      <Text letterSpacing={0} variant={ETextVariant.XL} {...priceProps}>
        {formatToMoney(purchasePrice)}
      </Text>
      {auctionType !== 'FIXED' && (
        <Text
          color={EColor.Neutral55}
          variant={ETextVariant.XS2}
          {...bpProps}
          bottom="6px"
          position="relative"
        >
          w/ Buyers Premium
        </Text>
      )}
    </Flex>
  );
}

ItemPrice.propTypes = {
  purchasePrice: PropTypes.string,
  bpProps: PropTypes.object,
  priceProps: PropTypes.object,
  flexProps: PropTypes.object,
  auctionType: PropTypes.string,
};

export default ItemPrice;
