import React from 'react';

import { Flex, Image } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const GridItemImage = ({ imageUrl, setPlaceholder }) => {
  return (
    <Flex
      height="100%"
      justify="center"
      position="relative"
      px="32px"
      py="32px"
      width="100%"
    >
      <Flex
        alignItems="center"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Image
          alt="Card image"
          borderRadius="5px"
          boxShadow="0px 8px 36px 0px #0000002B"
          loading="lazy"
          maxHeight="266px"
          objectFit="contain"
          src={imageUrl}
          onError={setPlaceholder}
        />
      </Flex>
    </Flex>
  );
};

GridItemImage.propTypes = {
  imageUrl: PropTypes.string,
  setPlaceholder: PropTypes.func,
};
